/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable max-lines */
import type { EditorID } from 'Cloud/Application/Editor/types';
import { getFeature } from 'Cloud/Application/FeaturesEs6';
import { processExtInfo as processExtInfoB2B } from 'reactApp/appHelpers/b2bHelpers';
import { IS_B2B_BIZ_USER } from 'reactApp/appHelpers/configHelpers';
import {
    GALLERY_B2B_IMAGES_EXTENSIONS,
    GALLERY_B2B_OTHER_EXTENSIONS,
    GALLERY_B2B_VIDEO_EXTENSIONS,
    GALLERY_EXTENSIONS,
} from 'reactApp/modules/gallery/gallery.data';
import { GalleryCategoryType } from 'reactApp/modules/gallery/gallery.types';
import type { Kind, SubKind } from 'reactApp/types/Tree';

const NON_PHOTO_IMAGES = ['gif', 'png', 'svg', 'svgz', 'apng'];

const ALBUMS_EXTENSIONS = [...GALLERY_EXTENSIONS, 'gif', 'ico', 'png'];

const MAX_SOURCE_VIEW_SIZE = 1048576;

/**
 * Описание для всех неизвестных типов файлов.
 */
const unknownExtension: IExtInfo = {
    kind: 'file',
    subKind: 'unknown',
    mimeType: 'application/octet-stream',
};

const extConfig: Record<string, IExtInfo> = {
    '264': {
        kind: 'video',
        subKind: 'video',
        maxPreviewableSize: 34359738368,
        maxThumbnailableSize: 34359738368,
        showThumbnailInList: true,
        isStreamingSupported: true,
        mimeType: 'video/264',
    },
    '265': {
        kind: 'video',
        subKind: 'video',
        maxPreviewableSize: 34359738368,
        maxThumbnailableSize: 34359738368,
        showThumbnailInList: true,
        isStreamingSupported: true,
        mimeType: 'video/265',
    },
    '3g2': {
        kind: 'video',
        subKind: 'video',
        maxPreviewableSize: 34359738368,
        maxThumbnailableSize: 34359738368,
        showThumbnailInList: true,
        mimeType: 'video/3gpp2',
    },
    '3gpp2': {
        kind: 'video',
        subKind: 'video',
        maxPreviewableSize: 34359738368,
        maxThumbnailableSize: 34359738368,
        showThumbnailInList: true,
        mimeType: 'video/3gpp2',
    },
    '3gp': {
        kind: 'video',
        subKind: 'video',
        maxPreviewableSize: 34359738368,
        maxThumbnailableSize: 34359738368,
        showThumbnailInList: true,
        isStreamingSupported: true,
        mimeType: 'video/3gpp',
    },
    '3gpp': {
        kind: 'video',
        subKind: 'video',
        maxPreviewableSize: 34359738368,
        maxThumbnailableSize: 34359738368,
        showThumbnailInList: true,
        isStreamingSupported: true,
        mimeType: 'video/3gpp',
    },
    '7z': {
        kind: 'file',
        subKind: 'archive',
        maxPreviewableSize: 50 * 1024 * 1024,
        mimeType: 'application/x-7z-compressed',
    },
    avc: {
        kind: 'video',
        subKind: 'video',
        maxPreviewableSize: 34359738368,
        maxThumbnailableSize: 34359738368,
        showThumbnailInList: true,
        isStreamingSupported: true,
        mimeType: 'video/avc',
    },
    ai: {
        kind: 'file',
        subKind: 'picture',
        mimeType: 'application/postscript',
    },
    aif: {
        kind: 'audio',
        subKind: 'audio',
        mimeType: 'audio/x-aiff',
    },
    aifc: {
        kind: 'audio',
        subKind: 'audio',
        mimeType: 'audio/x-aiff',
    },
    aiff: {
        kind: 'audio',
        subKind: 'audio',
        mimeType: 'audio/x-aiff',
    },
    apk: {
        kind: 'file',
        subKind: 'android-package',
        mimeType: 'application/vnd.android.package-archive',
    },
    apng: {
        kind: 'image',
        subKind: 'picture',
        maxPreviewableSize: 52428800,
        maxThumbnailableSize: 31457280,
        showThumbnailInList: true,
        mimeType: 'application/octet-stream',
        inlinePreviewable: true,
    },
    arj: {
        kind: 'file',
        subKind: 'archive',
        mimeType: 'application/octet-stream',
    },
    arw: {
        kind: 'image',
        subKind: 'picture',
        maxPreviewableSize: 62914560,
        maxThumbnailableSize: 62914560,
        showThumbnailInList: true,
        mimeType: 'image/x-sony-arw',
    },
    asf: {
        kind: 'video',
        subKind: 'video',
        maxPreviewableSize: 34359738368,
        maxThumbnailableSize: 34359738368,
        showThumbnailInList: true,
        mimeType: 'video/x-ms-asf',
    },
    asm: {
        kind: 'source',
        subKind: 'source',
        maxPreviewableSize: MAX_SOURCE_VIEW_SIZE,
        inlinePreviewable: true,
        mimeType: 'text/x-asm',
    },
    asx: {
        kind: 'file',
        subKind: 'playlist',
        mimeType: 'video/x-ms-asf',
    },
    atom: {
        kind: 'source',
        subKind: 'source',
        maxPreviewableSize: MAX_SOURCE_VIEW_SIZE,
        inlinePreviewable: true,
        mimeType: 'application/atom+xml',
    },
    avi: {
        kind: 'video',
        subKind: 'video',
        maxPreviewableSize: 34359738368,
        maxThumbnailableSize: 34359738368,
        showThumbnailInList: true,
        isStreamingSupported: true,
        mimeType: 'video/x-msvideo',
    },
    bat: {
        kind: 'file',
        subKind: 'exec',
        maxPreviewableSize: MAX_SOURCE_VIEW_SIZE,
        inlinePreviewable: true,
        mimeType: 'application/x-msdownload',
    },
    bmp: {
        kind: 'image',
        subKind: 'picture',
        mimeType: 'image/bmp',
    },
    bz2: {
        kind: 'file',
        subKind: 'archive',
        mimeType: 'application/x-bzip2',
    },
    bzip2: {
        kind: 'file',
        subKind: 'archive',
        mimeType: 'application/x-bzip2',
    },
    c: {
        kind: 'source',
        subKind: 'source',
        maxPreviewableSize: MAX_SOURCE_VIEW_SIZE,
        inlinePreviewable: true,
        mimeType: 'text/x-c',
    },
    cab: {
        kind: 'file',
        subKind: 'archive',
        mimeType: 'application/vnd.ms-cab-compressed',
    },
    cb7: {
        kind: 'file',
        subKind: 'ebook',
        mimeType: 'application/x-cbr',
    },
    cba: {
        kind: 'file',
        subKind: 'ebook',
        mimeType: 'application/x-cbr',
    },
    cbr: {
        kind: 'file',
        subKind: 'ebook',
        mimeType: 'application/x-cbr',
    },
    cbt: {
        kind: 'file',
        subKind: 'ebook',
        mimeType: 'application/x-cbr',
    },
    cbz: {
        kind: 'file',
        subKind: 'ebook',
        mimeType: 'application/x-cbr',
    },
    cc: {
        kind: 'source',
        subKind: 'source',
        maxPreviewableSize: MAX_SOURCE_VIEW_SIZE,
        inlinePreviewable: true,
        mimeType: 'text/x-c',
    },
    chm: {
        kind: 'document',
        subKind: 'html',
        mimeType: 'application/vnd.ms-htmlhelp',
    },
    clx: {
        kind: 'file',
        subKind: 'email',
        mimeType: 'application/octet-stream',
    },
    com: {
        kind: 'file',
        subKind: 'exec',
        mimeType: 'application/x-msdownload',
    },
    conf: {
        kind: 'file',
        subKind: 'plain-text',
        maxPreviewableSize: MAX_SOURCE_VIEW_SIZE,
        inlinePreviewable: true,
        mimeType: 'text/plain',
    },
    cpp: {
        kind: 'source',
        subKind: 'source',
        maxPreviewableSize: MAX_SOURCE_VIEW_SIZE,
        inlinePreviewable: true,
        mimeType: 'text/x-c',
    },
    cr2: {
        kind: 'image',
        subKind: 'picture',
        maxPreviewableSize: 62914560,
        maxThumbnailableSize: 62914560,
        showThumbnailInList: true,
        mimeType: 'image/x-canon-cr2',
    },
    crw: {
        kind: 'image',
        subKind: 'picture',
        mimeType: 'image/x-canon-crw',
    },
    css: {
        kind: 'source',
        subKind: 'source',
        maxPreviewableSize: MAX_SOURCE_VIEW_SIZE,
        inlinePreviewable: true,
        mimeType: 'text/css',
    },
    csv: {
        kind: 'document',
        subKind: 'spreadsheet',
        maxPreviewableSize: 26214400,
        maxEditableSize: 26214400,
        mimeType: 'text/csv',
    },
    cxx: {
        kind: 'source',
        subKind: 'source',
        maxPreviewableSize: MAX_SOURCE_VIEW_SIZE,
        inlinePreviewable: true,
        mimeType: 'text/x-c',
    },
    dat: {
        kind: 'source',
        subKind: 'source',
        maxPreviewableSize: MAX_SOURCE_VIEW_SIZE,
        inlinePreviewable: true,
        mimeType: 'text/css',
    },
    deb: {
        kind: 'file',
        subKind: 'debian-package',
        mimeType: 'application/x-debian-package',
        inlinePreviewable: true,
    },
    diff: {
        kind: 'file',
        subKind: 'plain-text',
        maxPreviewableSize: MAX_SOURCE_VIEW_SIZE,
        inlinePreviewable: true,
        mimeType: 'text/x-diff',
    },
    divx: {
        kind: 'video',
        subKind: 'video',
        maxPreviewableSize: 34359738368,
        maxThumbnailableSize: 34359738368,
        showThumbnailInList: true,
        isStreamingSupported: true,
        mimeType: 'video/divx',
    },
    djv: {
        kind: 'file',
        subKind: 'ebook',
        mimeType: 'image/vnd.djvu',
        maxPreviewableSize: 8 * 1024 ** 2 * 25, // 25 Мб
    },
    djvu: {
        kind: 'file',
        subKind: 'ebook',
        mimeType: 'image/vnd.djvu',
        maxPreviewableSize: 8 * 1024 ** 2 * 25, // 25 Мб
    },
    dmg: {
        kind: 'file',
        subKind: 'archive',
        mimeType: 'application/x-apple-diskimage',
    },
    dng: {
        kind: 'image',
        subKind: 'picture',
        maxPreviewableSize: 62914560,
        maxThumbnailableSize: 62914560,
        showThumbnailInList: true,
        mimeType: 'image/x-adobe-dng',
    },
    doc: {
        kind: 'document',
        subKind: 'text',
        maxPreviewableSize: 10485760,
        maxEditableSize: 10485760,
        maxThumbnailableSize: 5242880,
        onlyThumbnailableByHash: true,
        mimeType: 'application/msword',
        msIcon: 'word-online',
    },
    docm: {
        kind: 'document',
        subKind: 'text',
        maxPreviewableSize: 10485760,
        mimeType: 'application/vnd.ms-word.template.macroEnabled.12',
        msIcon: 'word-online',
    },
    docx: {
        kind: 'document',
        subKind: 'text',
        maxPreviewableSize: 10485760,
        maxEditableSize: 10485760,
        maxThumbnailableSize: 5242880,
        onlyThumbnailableByHash: true,
        mimeType: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        msIcon: 'word-online',
    },
    dot: {
        kind: 'document',
        subKind: 'text',
        maxPreviewableSize: 52428800,
        mimeType: 'application/msword',
        msIcon: 'word-online',
    },
    dotx: {
        kind: 'document',
        subKind: 'text',
        maxPreviewableSize: 10485760,
        maxEditableSize: 10485760,
        mimeType: 'application/vnd.openxmlformats-officedocument.wordprocessingml.template',
        msIcon: 'word-online',
    },
    dtd: {
        kind: 'source',
        subKind: 'source',
        maxPreviewableSize: MAX_SOURCE_VIEW_SIZE,
        inlinePreviewable: true,
        mimeType: 'application/xml-dtd',
    },
    ear: {
        kind: 'file',
        subKind: 'archive',
        mimeType: 'application/java-archive',
    },
    eml: {
        kind: 'file',
        subKind: 'email',
        mimeType: 'message/rfc822',
    },
    epub: {
        kind: 'file',
        subKind: 'ebook',
        mimeType: 'application/epub+zip',
        disablePreviewAsEditorOnPublic: true,
        maxPreviewableSize: 20971520, // 20 Мб
    },
    exe: {
        kind: 'file',
        subKind: 'exec',
        mimeType: 'application/x-msdownload',
    },
    f: {
        kind: 'source',
        subKind: 'source',
        maxPreviewableSize: MAX_SOURCE_VIEW_SIZE,
        inlinePreviewable: true,
        mimeType: 'text/x-fortran',
    },
    f4v: {
        kind: 'video',
        subKind: 'video',
        maxPreviewableSize: 34359738368,
        maxThumbnailableSize: 34359738368,
        showThumbnailInList: true,
        isStreamingSupported: true,
        mimeType: 'video/f4v',
    },
    f77: {
        kind: 'source',
        subKind: 'source',
        maxPreviewableSize: MAX_SOURCE_VIEW_SIZE,
        inlinePreviewable: true,
        mimeType: 'text/x-fortran',
    },
    f90: {
        kind: 'source',
        subKind: 'source',
        maxPreviewableSize: MAX_SOURCE_VIEW_SIZE,
        inlinePreviewable: true,
        mimeType: 'text/x-fortran',
    },
    fb2: {
        kind: 'file',
        subKind: 'ebook',
        mimeType: 'application/octet-stream',
        disablePreviewAsEditorOnPublic: true,
        maxPreviewableSize: 20971520, // 20 Мб
    },
    flac: {
        kind: 'audio',
        subKind: 'audio',
        maxPreviewableSize: 34359738368,
        isStreamingSupported: true,
        mimeType: 'audio/x-flac',
    },
    flv: {
        kind: 'video',
        subKind: 'video',
        maxPreviewableSize: 34359738368,
        maxThumbnailableSize: 34359738368,
        showThumbnailInList: true,
        mimeType: 'video/x-flv',
    },
    for: {
        kind: 'source',
        subKind: 'source',
        maxPreviewableSize: MAX_SOURCE_VIEW_SIZE,
        inlinePreviewable: true,
        mimeType: 'text/x-fortran',
    },
    gemspec: {
        kind: 'source',
        subKind: 'source',
        maxPreviewableSize: MAX_SOURCE_VIEW_SIZE,
        inlinePreviewable: true,
        mimeType: 'text/x-script.ruby',
    },
    gif: {
        kind: 'image',
        subKind: 'picture',
        maxPreviewableSize: 52428800,
        maxThumbnailableSize: 31457280,
        showThumbnailInList: true,
        mimeType: 'image/gif',
        inlinePreviewable: true,
    },
    gz: {
        kind: 'file',
        subKind: 'archive',
        mimeType: 'application/x-gzip',
    },
    h: {
        kind: 'source',
        subKind: 'source',
        maxPreviewableSize: MAX_SOURCE_VIEW_SIZE,
        inlinePreviewable: true,
        mimeType: 'text/x-c',
    },
    hdp: {
        kind: 'image',
        subKind: 'picture',
        mimeType: 'image/vnd.ms-photo',
    },
    heic: {
        kind: 'image',
        subKind: 'picture',
        maxPreviewableSize: 52428800,
        maxEditableSize: 20971520,
        maxThumbnailableSize: 20971520,
        showThumbnailInList: true,
        mimeType: 'image/heic',
    },
    heif: {
        kind: 'image',
        subKind: 'picture',
        maxPreviewableSize: 52428800,
        maxEditableSize: 20971520,
        maxThumbnailableSize: 20971520,
        showThumbnailInList: true,
        mimeType: 'image/heif',
    },
    hevc: {
        kind: 'video',
        subKind: 'video',
        maxPreviewableSize: 34359738368,
        maxThumbnailableSize: 34359738368,
        showThumbnailInList: true,
        mimeType: 'video/265',
    },
    hh: {
        kind: 'source',
        subKind: 'source',
        maxPreviewableSize: MAX_SOURCE_VIEW_SIZE,
        inlinePreviewable: true,
        mimeType: 'text/x-c',
    },
    hpp: {
        kind: 'source',
        subKind: 'source',
        maxPreviewableSize: MAX_SOURCE_VIEW_SIZE,
        inlinePreviewable: true,
        mimeType: 'text/x-c',
    },
    htm: {
        kind: 'file',
        subKind: 'html',
        maxPreviewableSize: MAX_SOURCE_VIEW_SIZE,
        inlinePreviewable: true,
        mimeType: 'text/html',
    },
    html: {
        kind: 'file',
        subKind: 'html',
        maxPreviewableSize: MAX_SOURCE_VIEW_SIZE,
        inlinePreviewable: true,
        mimeType: 'text/html',
    },
    hyp: {
        kind: 'file',
        subKind: 'archive',
        mimeType: 'application/x-hyp-compressed',
    },
    ico: {
        kind: 'file',
        subKind: 'picture',
        mimeType: 'image/x-icon',
    },
    ics: {
        kind: 'file',
        subKind: 'calendar',
        mimeType: 'text/calendar',
    },
    ifb: {
        kind: 'file',
        subKind: 'calendar',
        mimeType: 'text/calendar',
    },
    ini: {
        kind: 'file',
        subKind: 'plain-text',
        maxPreviewableSize: MAX_SOURCE_VIEW_SIZE,
        inlinePreviewable: true,
        mimeType: 'application/octet-stream',
    },
    j2k: {
        kind: 'image',
        subKind: 'picture',
        mimeType: 'image/jp2',
    },
    jar: {
        kind: 'file',
        subKind: 'archive',
        mimeType: 'application/java-archive',
    },
    java: {
        kind: 'source',
        subKind: 'source',
        maxPreviewableSize: MAX_SOURCE_VIEW_SIZE,
        inlinePreviewable: true,
        mimeType: 'text/x-java-source',
    },
    jp2: {
        kind: 'image',
        subKind: 'picture',
        mimeType: 'image/jp2',
    },
    jpe: {
        kind: 'image',
        subKind: 'picture',
        maxPreviewableSize: 52428800,
        maxThumbnailableSize: 31457280,
        showThumbnailInList: true,
        mimeType: 'image/jpeg',
        inlinePreviewable: true,
    },
    jpeg: {
        kind: 'image',
        subKind: 'picture',
        maxPreviewableSize: 52428800,
        maxEditableSize: 20971520,
        maxThumbnailableSize: 31457280,
        showThumbnailInList: true,
        mimeType: 'image/jpeg',
        inlinePreviewable: true,
    },
    jpf: {
        kind: 'image',
        subKind: 'picture',
        showThumbnailInList: true,
        mimeType: 'image/jpx',
    },
    jpg: {
        kind: 'image',
        subKind: 'picture',
        maxPreviewableSize: 52428800,
        maxEditableSize: 20971520,
        maxThumbnailableSize: 31457280,
        showThumbnailInList: true,
        mimeType: 'image/jpeg',
        inlinePreviewable: true,
    },
    jpm: {
        kind: 'image',
        subKind: 'picture',
        mimeType: 'image/jpm',
    },
    jpx: {
        kind: 'image',
        subKind: 'picture',
        showThumbnailInList: true,
        mimeType: 'image/jpx',
    },
    js: {
        kind: 'source',
        subKind: 'source',
        maxPreviewableSize: MAX_SOURCE_VIEW_SIZE,
        inlinePreviewable: true,
        mimeType: 'application/javascript',
    },
    json: {
        kind: 'light-markup',
        subKind: 'json',
        maxPreviewableSize: MAX_SOURCE_VIEW_SIZE,
        inlinePreviewable: true,
        mimeType: 'application/json',
    },
    jxr: {
        kind: 'image',
        subKind: 'picture',
        mimeType: 'image/vnd.ms-photo',
    },
    keynote: {
        kind: 'file',
        subKind: 'presentation',
        mimeType: 'application/octet-stream',
    },
    key: {
        kind: 'file',
        subKind: 'presentation',
        mimeType: 'aapplication/octet-stream',
    },
    ldif: {
        kind: 'file',
        subKind: 'card',
        mimeType: 'application/octet-stream',
    },
    lng: {
        kind: 'file',
        subKind: 'plain-text',
        maxPreviewableSize: MAX_SOURCE_VIEW_SIZE,
        inlinePreviewable: true,
        mimeType: 'application/octet-stream',
    },
    log: {
        kind: 'file',
        subKind: 'plain-text',
        maxPreviewableSize: MAX_SOURCE_VIEW_SIZE,
        inlinePreviewable: true,
        mimeType: 'text/plain',
    },
    m2ts: {
        kind: 'video',
        subKind: 'video',
        maxPreviewableSize: 34359738368,
        maxThumbnailableSize: 34359738368,
        showThumbnailInList: true,
        mimeType: 'video/MP2T',
    },
    m2v: {
        kind: 'video',
        subKind: 'video',
        maxPreviewableSize: 34359738368,
        maxThumbnailableSize: 34359738368,
        showThumbnailInList: true,
        mimeType: 'video/mpeg',
    },
    m3u: {
        kind: 'file',
        subKind: 'playlist',
        mimeType: 'audio/x-mpegurl',
    },
    m3u8: {
        kind: 'file',
        subKind: 'playlist',
        mimeType: 'application/x-mpegurl',
    },
    aac: {
        kind: 'audio',
        subKind: 'audio',
        maxPreviewableSize: 34359738368,
        isStreamingSupported: true,
        isNativeSupported: true,
        mimeType: 'audio/aac',
    },
    mp3: {
        kind: 'audio',
        subKind: 'audio',
        maxPreviewableSize: 34359738368,
        isStreamingSupported: true,
        isNativeSupported: true,
        mimeType: 'audio/mpeg',
    },
    mp4: {
        kind: 'video',
        subKind: 'video',
        maxPreviewableSize: 34359738368,
        maxThumbnailableSize: 34359738368,
        showThumbnailInList: true,
        isStreamingSupported: true,
        isNativeSupported: true,
        mimeType: 'video/mp4',
    },
    mp4v: {
        kind: 'video',
        subKind: 'video',
        maxPreviewableSize: 34359738368,
        maxThumbnailableSize: 34359738368,
        showThumbnailInList: true,
        mimeType: 'video/mp4',
    },
    m4a: {
        kind: 'audio',
        subKind: 'audio',
        maxPreviewableSize: 34359738368,
        isStreamingSupported: true,
        isNativeSupported: true,
        mimeType: 'audio/mp4',
    },
    m4v: {
        kind: 'video',
        subKind: 'video',
        maxPreviewableSize: 34359738368,
        maxThumbnailableSize: 34359738368,
        showThumbnailInList: true,
        isStreamingSupported: true,
        isNativeSupported: true,
        mimeType: 'video/x-m4v',
    },
    mad: {
        kind: 'light-markup',
        subKind: 'markdown',
        mimeType: 'text/x-markdown',
    },
    masm: {
        kind: 'source',
        subKind: 'source',
        mimeType: 'application/octet-stream',
    },
    mathml: {
        kind: 'file',
        subKind: 'html',
        mimeType: 'application/mathml+xml',
    },
    matroska: {
        kind: 'video',
        subKind: 'video',
        maxPreviewableSize: 34359738368,
        maxThumbnailableSize: 34359738368,
        showThumbnailInList: true,
        mimeType: 'video/x-matroska',
    },
    mbox: {
        kind: 'file',
        subKind: 'email',
        mimeType: 'application/mbox',
    },
    md: {
        kind: 'light-markup',
        subKind: 'markdown',
        maxPreviewableSize: MAX_SOURCE_VIEW_SIZE,
        inlinePreviewable: true,
        mimeType: 'text/x-markdown',
    },
    mht: {
        kind: 'file',
        subKind: 'html',
        maxPreviewableSize: MAX_SOURCE_VIEW_SIZE,
        inlinePreviewable: true,
        mimeType: 'application/octet-stream',
    },
    mhtml: {
        kind: 'file',
        subKind: 'html',
        maxPreviewableSize: MAX_SOURCE_VIEW_SIZE,
        inlinePreviewable: true,
        mimeType: 'application/octet-stream',
    },
    mid: {
        kind: 'audio',
        subKind: 'audio',
        mimeType: 'audio/midi',
    },
    midi: {
        kind: 'audio',
        subKind: 'audio',
        mimeType: 'audio/midi',
    },
    mime: {
        kind: 'file',
        subKind: 'plain-text',
        maxPreviewableSize: MAX_SOURCE_VIEW_SIZE,
        inlinePreviewable: true,
        mimeType: 'message/rfc822',
    },
    mk3d: {
        kind: 'video',
        subKind: 'video',
        maxPreviewableSize: 34359738368,
        maxThumbnailableSize: 34359738368,
        showThumbnailInList: true,
        mimeType: 'video/x-matroska',
    },
    mka: {
        kind: 'video',
        subKind: 'video',
        mimeType: 'audio/x-matroska',
    },
    mks: {
        kind: 'video',
        subKind: 'video',
        mimeType: 'video/x-matroska',
    },
    mkv: {
        kind: 'video',
        subKind: 'video',
        maxPreviewableSize: 34359738368,
        maxThumbnailableSize: 34359738368,
        showThumbnailInList: true,
        isStreamingSupported: true,
        mimeType: 'video/x-matroska',
    },
    mml: {
        kind: 'file',
        subKind: 'html',
        maxPreviewableSize: MAX_SOURCE_VIEW_SIZE,
        inlinePreviewable: true,
        mimeType: 'application/mathml+xml',
    },
    mng: {
        kind: 'video',
        subKind: 'video',
        mimeType: 'video/x-mng',
    },
    mov: {
        kind: 'video',
        subKind: 'video',
        maxPreviewableSize: 34359738368,
        maxThumbnailableSize: 34359738368,
        showThumbnailInList: true,
        isStreamingSupported: true,
        mimeType: 'video/quicktime',
    },
    mpeg: {
        kind: 'video',
        subKind: 'video',
        maxPreviewableSize: 34359738368,
        maxThumbnailableSize: 34359738368,
        showThumbnailInList: true,
        mimeType: 'video/mpeg',
    },
    mpg: {
        kind: 'video',
        subKind: 'video',
        maxPreviewableSize: 34359738368,
        maxThumbnailableSize: 34359738368,
        showThumbnailInList: true,
        mimeType: 'video/mpeg',
    },
    mpo: {
        kind: 'image',
        subKind: 'picture',
        maxPreviewableSize: 31457280,
        maxThumbnailableSize: 31457280,
        showThumbnailInList: true,
        mimeType: 'image/jpeg',
    },
    msg: {
        kind: 'file',
        subKind: 'email',
        mimeType: 'application/octet-stream',
    },
    msi: {
        kind: 'file',
        subKind: 'exec',
        mimeType: 'application/x-msdownload',
    },
    mts: {
        kind: 'video',
        subKind: 'video',
        mimeType: 'model/vnd.mts',
    },
    mxf: {
        kind: 'video',
        subKind: 'video',
        maxPreviewableSize: 34359738368,
        maxThumbnailableSize: 34359738368,
        showThumbnailInList: true,
        mimeType: 'application/mxf',
    },
    nef: {
        kind: 'image',
        subKind: 'picture',
        maxPreviewableSize: 62914560,
        maxThumbnailableSize: 62914560,
        showThumbnailInList: true,
        mimeType: 'image/x-nikon-nef',
    },
    nrw: {
        kind: 'image',
        subKind: 'picture',
        maxPreviewableSize: 62914560,
        maxThumbnailableSize: 62914560,
        showThumbnailInList: true,
        mimeType: 'image/x-nikon-nrw',
    },
    numbers: {
        kind: 'file',
        subKind: 'spreadsheet',
        mimeType: 'application/octet-stream',
    },
    odc: {
        kind: 'file',
        subKind: 'chart',
        mimeType: 'application/vnd.oasis.opendocument.chart',
    },
    odf: {
        kind: 'file',
        subKind: 'graphics',
        mimeType: 'application/vnd.oasis.opendocument.formula',
    },
    odg: {
        kind: 'document',
        subKind: 'graphics',
        mimeType: 'application/vnd.oasis.opendocument.graphics',
    },
    odi: {
        kind: 'document',
        subKind: 'graphics',
        mimeType: 'application/vnd.oasis.opendocument.image',
    },
    odm: {
        kind: 'document',
        subKind: 'graphics',
        mimeType: 'application/vnd.oasis.opendocument.text-master',
    },
    odp: {
        kind: 'document',
        subKind: 'presentation',
        maxPreviewableSize: 83886080,
        maxEditableSize: 83886080,
        mimeType: 'application/vnd.oasis.opendocument.presentation',
        msIcon: 'powerpoint-online',
    },
    ods: {
        kind: 'document',
        subKind: 'spreadsheet',
        maxPreviewableSize: 25165824,
        maxEditableSize: 25165824,
        mimeType: 'application/vnd.oasis.opendocument.spreadsheet',
        msIcon: 'excel-online',
    },
    odt: {
        kind: 'document',
        subKind: 'text',
        maxPreviewableSize: 52428800,
        maxEditableSize: 52428800,
        mimeType: 'application/vnd.oasis.opendocument.text',
        msIcon: 'word-online',
    },
    oga: {
        kind: 'audio',
        subKind: 'audio',
        maxPreviewableSize: 34359738368,
        isStreamingSupported: true,
        isNativeSupported: true,
        mimeType: 'audio/ogg',
    },
    opus: {
        kind: 'audio',
        subKind: 'audio',
        maxPreviewableSize: 34359738368,
        isStreamingSupported: true,
        isNativeSupported: true,
        mimeType: 'audio/ogg',
    },
    ogg: {
        kind: 'audio',
        subKind: 'audio',
        maxPreviewableSize: 34359738368,
        isStreamingSupported: true,
        isNativeSupported: true,
        mimeType: 'audio/ogg',
    },
    ogv: {
        kind: 'video',
        subKind: 'video',
        maxPreviewableSize: 34359738368,
        maxThumbnailableSize: 34359738368,
        showThumbnailInList: true,
        isStreamingSupported: true,
        isNativeSupported: true,
        mimeType: 'video/ogg',
    },
    otc: {
        kind: 'file',
        subKind: 'chart',
        mimeType: 'application/vnd.oasis.opendocument.chart-template',
    },
    otf: {
        kind: 'file',
        subKind: 'graphics',
        mimeType: 'application/x-font-otf',
    },
    otg: {
        kind: 'file',
        subKind: 'graphics',
        mimeType: 'application/vnd.oasis.opendocument.graphics-template',
    },
    oth: {
        kind: 'file',
        subKind: 'graphics',
        mimeType: 'application/vnd.oasis.opendocument.text-web',
    },
    oti: {
        kind: 'file',
        subKind: 'graphics',
        mimeType: 'application/vnd.oasis.opendocument.image-template',
    },
    otp: {
        kind: 'file',
        subKind: 'presentation',
        mimeType: 'application/vnd.oasis.opendocument.presentation-template',
    },
    ots: {
        kind: 'document',
        subKind: 'spreadsheet',
        maxPreviewableSize: 25165824,
        maxEditableSize: 25165824,
        mimeType: 'application/vnd.oasis.opendocument.spreadsheet-template',
    },
    ott: {
        kind: 'document',
        subKind: 'text',
        maxPreviewableSize: 25165824,
        maxEditableSize: 25165824,
        mimeType: 'application/vnd.oasis.opendocument.text-template',
    },
    p: {
        kind: 'source',
        subKind: 'source',
        maxPreviewableSize: MAX_SOURCE_VIEW_SIZE,
        inlinePreviewable: true,
        mimeType: 'text/x-pascal',
    },
    pages: {
        kind: 'file',
        subKind: 'text',
        mimeType: 'application/octet-stream',
    },
    pas: {
        kind: 'source',
        subKind: 'source',
        maxPreviewableSize: MAX_SOURCE_VIEW_SIZE,
        inlinePreviewable: true,
        mimeType: 'text/x-pascal',
    },
    pdf: {
        kind: 'pdf,document',
        subKind: 'pdf',
        maxPreviewableSize: 157286400,
        maxEditableSize: 20 * 1024 * 1024,
        maxThumbnailableSize: 5242880,
        onlyThumbnailableByHash: true,
        mimeType: 'application/pdf',
        inlinePreviewable: true,
    },
    php: {
        kind: 'source',
        subKind: 'source',
        maxPreviewableSize: MAX_SOURCE_VIEW_SIZE,
        inlinePreviewable: true,
        mimeType: 'application/octet-stream',
    },
    pl: {
        kind: 'source',
        subKind: 'source',
        maxPreviewableSize: MAX_SOURCE_VIEW_SIZE,
        inlinePreviewable: true,
        mimeType: 'text/x-script.perl',
    },
    pm: {
        kind: 'source',
        subKind: 'source',
        maxPreviewableSize: MAX_SOURCE_VIEW_SIZE,
        inlinePreviewable: true,
        mimeType: 'text/x-script.perl-module',
    },
    png: {
        kind: 'image',
        subKind: 'picture',
        maxPreviewableSize: 52428800,
        maxEditableSize: 20971520,
        maxThumbnailableSize: 31457280,
        showThumbnailInList: true,
        mimeType: 'image/png',
        inlinePreviewable: true,
    },
    pot: {
        kind: 'document',
        subKind: 'presentation',
        maxPreviewableSize: 52428800,
        mimeType: 'application/vnd.ms-powerpoint',
        msIcon: 'powerpoint-online',
    },
    potm: {
        kind: 'document',
        subKind: 'presentation',
        maxPreviewableSize: 52428800,
        mimeType: 'application/vnd.ms-powerpoint.template.macroEnabled.12',
        msIcon: 'powerpoint-online',
    },
    potx: {
        kind: 'document',
        subKind: 'presentation',
        maxPreviewableSize: 52428800,
        mimeType: 'application/vnd.openxmlformats-officedocument.presentationml.template',
        msIcon: 'powerpoint-online',
    },
    pps: {
        kind: 'document',
        subKind: 'presentation',
        maxPreviewableSize: 157286400,
        mimeType: 'application/vnd.ms-powerpoint',
        msIcon: 'powerpoint-online',
    },
    ppsm: {
        kind: 'document',
        subKind: 'presentation',
        maxPreviewableSize: 157286400,
        mimeType: 'application/vnd.ms-powerpoint.slideshow.macroEnabled.12',
        msIcon: 'powerpoint-online',
    },
    ppsx: {
        kind: 'document',
        subKind: 'presentation',
        maxPreviewableSize: 157286400,
        maxEditableSize: 157286400,
        mimeType: 'application/vnd.openxmlformats-officedocument.presentationml.slideshow',
        msIcon: 'powerpoint-online',
    },
    ppt: {
        kind: 'document',
        subKind: 'presentation',
        maxPreviewableSize: 83886080,
        maxEditableSize: 83886080,
        maxThumbnailableSize: 5242880,
        onlyThumbnailableByHash: true,
        mimeType: 'application/vnd.ms-powerpoint',
        msIcon: 'powerpoint-online',
    },
    pptm: {
        kind: 'document',
        subKind: 'presentation',
        maxPreviewableSize: 83886080,
        mimeType: 'application/vnd.ms-powerpoint.presentation.macroEnabled.12',
        msIcon: 'powerpoint-online',
    },
    pptx: {
        kind: 'document',
        subKind: 'presentation',
        maxPreviewableSize: 157286400,
        maxEditableSize: 83886080,
        maxThumbnailableSize: 5242880,
        onlyThumbnailableByHash: true,
        mimeType: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
        msIcon: 'powerpoint-online',
    },
    psd: {
        kind: 'file',
        subKind: 'photoshop',
        mimeType: 'image/vnd.adobe.photoshop',
    },
    py: {
        kind: 'source',
        subKind: 'source',
        maxPreviewableSize: MAX_SOURCE_VIEW_SIZE,
        inlinePreviewable: true,
        mimeType: 'text/x-script.python',
    },
    qt: {
        kind: 'video',
        subKind: 'video',
        maxPreviewableSize: 34359738368,
        maxThumbnailableSize: 34359738368,
        showThumbnailInList: true,
        mimeType: 'video/quicktime',
    },
    ra: {
        kind: 'audio',
        subKind: 'audio',
        mimeType: 'audio/x-pn-realaudio',
    },
    rake: {
        kind: 'source',
        subKind: 'source',
        maxPreviewableSize: MAX_SOURCE_VIEW_SIZE,
        inlinePreviewable: true,
        mimeType: 'text/x-script.ruby',
    },
    ram: {
        kind: 'audio',
        subKind: 'audio',
        mimeType: 'audio/x-pn-realaudio',
    },
    rar: {
        kind: 'file',
        subKind: 'archive',
        maxPreviewableSize: 50 * 1024 * 1024,
        mimeType: 'application/x-rar-compressed',
    },
    raw: {
        kind: 'file',
        subKind: 'picture',
        mimeType: 'image/raw',
    },
    rb: {
        kind: 'source',
        subKind: 'source',
        maxPreviewableSize: MAX_SOURCE_VIEW_SIZE,
        inlinePreviewable: true,
        mimeType: 'text/x-script.ruby',
    },
    rss: {
        kind: 'source',
        subKind: 'web-feed',
        mimeType: 'application/rss+xml',
    },
    rtf: {
        kind: 'document',
        subKind: 'text',
        maxPreviewableSize: 52428800,
        maxEditableSize: 52428800,
        mimeType: 'application/rtf',
    },
    ru: {
        kind: 'source',
        subKind: 'source',
        maxPreviewableSize: MAX_SOURCE_VIEW_SIZE,
        inlinePreviewable: true,
        mimeType: 'text/x-script.ruby',
    },
    s: {
        kind: 'source',
        subKind: 'source',
        maxPreviewableSize: MAX_SOURCE_VIEW_SIZE,
        inlinePreviewable: true,
        mimeType: 'text/x-asm',
    },
    sgm: {
        kind: 'source',
        subKind: 'source',
        maxPreviewableSize: MAX_SOURCE_VIEW_SIZE,
        inlinePreviewable: true,
        mimeType: 'text/sgml',
    },
    sgml: {
        kind: 'source',
        subKind: 'source',
        maxPreviewableSize: MAX_SOURCE_VIEW_SIZE,
        inlinePreviewable: true,
        mimeType: 'text/sgml',
    },
    sh: {
        kind: 'file',
        subKind: 'exec',
        maxPreviewableSize: MAX_SOURCE_VIEW_SIZE,
        inlinePreviewable: true,
        mimeType: 'application/x-sh',
    },
    sql: {
        kind: 'source',
        subKind: 'source',
        maxPreviewableSize: MAX_SOURCE_VIEW_SIZE,
        inlinePreviewable: true,
        mimeType: 'text/sql',
    },
    sr2: {
        kind: 'image',
        subKind: 'picture',
        maxPreviewableSize: 62914560,
        maxThumbnailableSize: 62914560,
        showThumbnailInList: true,
        mimeType: 'image/x-sony-sr2',
    },
    srf: {
        kind: 'image',
        subKind: 'picture',
        mimeType: 'image/x-sony-srf',
    },
    svg: {
        kind: 'image',
        subKind: 'picture',
        mimeType: 'image/svg+xml',
    },
    svgz: {
        kind: 'image',
        subKind: 'picture',
        mimeType: 'image/svg+xml',
    },
    swf: {
        kind: 'file',
        subKind: 'flash',
        mimeType: 'application/x-shockwave-flash',
    },
    t: {
        kind: 'source',
        subKind: 'source',
        maxPreviewableSize: MAX_SOURCE_VIEW_SIZE,
        inlinePreviewable: true,
        mimeType: 'text/troff',
    },
    tar: {
        kind: 'file',
        subKind: 'archive',
        mimeType: 'application/x-tar',
    },
    tbz: {
        kind: 'file',
        subKind: 'archive',
        mimeType: 'application/x-bzip-compressed-tar',
    },
    tex: {
        kind: 'source',
        subKind: 'source',
        maxPreviewableSize: MAX_SOURCE_VIEW_SIZE,
        inlinePreviewable: true,
        mimeType: 'application/x-tex',
    },
    texi: {
        kind: 'source',
        subKind: 'source',
        maxPreviewableSize: MAX_SOURCE_VIEW_SIZE,
        inlinePreviewable: true,
        mimeType: 'application/x-texinfo',
    },
    texinfo: {
        kind: 'source',
        subKind: 'source',
        maxPreviewableSize: MAX_SOURCE_VIEW_SIZE,
        inlinePreviewable: true,
        mimeType: 'application/x-texinfo',
    },
    text: {
        kind: 'file',
        subKind: 'plain-text',
        maxPreviewableSize: MAX_SOURCE_VIEW_SIZE,
        inlinePreviewable: true,
        mimeType: 'text/plain',
    },
    tga: {
        kind: 'file',
        subKind: 'picture',
        mimeType: 'image/x-tga',
    },
    tgz: {
        kind: 'file',
        subKind: 'archive',
        mimeType: 'application/octet-stream',
    },
    tif: {
        kind: 'image',
        subKind: 'picture',
        maxPreviewableSize: 52428800,
        maxThumbnailableSize: 52428800,
        showThumbnailInList: true,
        mimeType: 'image/tiff',
    },
    tiff: {
        kind: 'image',
        subKind: 'picture',
        maxPreviewableSize: 52428800,
        maxThumbnailableSize: 52428800,
        showThumbnailInList: true,
        mimeType: 'image/tiff',
    },
    torrent: {
        kind: 'file',
        subKind: 'torrent',
        mimeType: 'application/x-bittorrent',
    },
    ts: {
        kind: 'video',
        subKind: 'video',
        maxPreviewableSize: 34359738368,
        maxThumbnailableSize: 34359738368,
        showThumbnailInList: true,
        isStreamingSupported: true,
        mimeType: 'video/MP2T',
    },
    tsa: {
        kind: 'video',
        subKind: 'video',
        maxPreviewableSize: 34359738368,
        maxThumbnailableSize: 34359738368,
        showThumbnailInList: true,
        mimeType: 'video/MP2T',
    },
    tsv: {
        kind: 'video',
        subKind: 'video',
        mimeType: 'text/tab-separated-values',
    },
    txi: {
        kind: 'source',
        subKind: 'source',
        maxPreviewableSize: MAX_SOURCE_VIEW_SIZE,
        inlinePreviewable: true,
        mimeType: 'application/x-texinfo',
    },
    txt: {
        kind: 'text',
        subKind: 'plain-text',
        maxPreviewableSize: 5242880,
        maxThumbnailableSize: 2097152,
        onlyThumbnailableByHash: true,
        mimeType: 'text/plain',
        inlinePreviewable: true,
    },
    vbs: {
        kind: 'source',
        subKind: 'source',
        maxPreviewableSize: MAX_SOURCE_VIEW_SIZE,
        inlinePreviewable: true,
        mimeType: 'application/octet-stream',
    },
    vcard: {
        kind: 'file',
        subKind: 'card',
        mimeType: 'text/vcard',
    },
    vcf: {
        kind: 'file',
        subKind: 'card',
        mimeType: 'text/x-vcard',
    },
    vcs: {
        kind: 'file',
        subKind: 'calendar',
        mimeType: 'text/x-vcalendar',
    },
    vob: {
        kind: 'video',
        subKind: 'video',
        maxPreviewableSize: 34359738368,
        maxThumbnailableSize: 34359738368,
        showThumbnailInList: true,
        mimeType: 'video/x-ms-vob',
    },
    war: {
        kind: 'file',
        subKind: 'archive',
        mimeType: 'application/java-archive',
    },
    wav: {
        kind: 'audio',
        subKind: 'audio',
        maxPreviewableSize: 34359738368,
        isStreamingSupported: true,
        isNativeSupported: true,
        mimeType: 'audio/x-wav',
    },
    wave: {
        kind: 'audio',
        subKind: 'audio',
        maxPreviewableSize: 34359738368,
        mimeType: 'audio/x-wav',
    },
    wdp: {
        kind: 'image',
        subKind: 'picture',
        mimeType: 'image/vnd.ms-photo',
    },
    webm: {
        kind: 'video',
        subKind: 'video',
        maxPreviewableSize: 34359738368,
        maxThumbnailableSize: 34359738368,
        showThumbnailInList: true,
        isStreamingSupported: true,
        isNativeSupported: true,
        mimeType: 'video/webm',
    },
    webp: {
        kind: 'image',
        subKind: 'picture',
        maxPreviewableSize: 52428800,
        maxThumbnailableSize: 31457280,
        showThumbnailInList: true,
        mimeType: 'image/webp',
        inlinePreviewable: true,
    },
    wma: {
        kind: 'audio',
        subKind: 'audio',
        mimeType: 'audio/x-ms-wma',
    },
    wmv: {
        kind: 'video',
        subKind: 'video',
        maxPreviewableSize: 34359738368,
        maxThumbnailableSize: 34359738368,
        showThumbnailInList: true,
        isStreamingSupported: true,
        mimeType: 'video/x-ms-wmv',
    },
    wmx: {
        kind: 'video',
        subKind: 'video',
        maxPreviewableSize: 34359738368,
        maxThumbnailableSize: 34359738368,
        showThumbnailInList: true,
        mimeType: 'video/x-ms-wmx',
    },
    wpd: {
        kind: 'document',
        subKind: 'text',
        mimeType: 'application/vnd.wordperfect',
        msIcon: 'word-online',
    },
    wps: {
        kind: 'document',
        subKind: 'text',
        mimeType: 'application/vnd.ms-works',
        msIcon: 'word-online',
    },
    xhtml: {
        kind: 'file',
        subKind: 'html',
        maxPreviewableSize: MAX_SOURCE_VIEW_SIZE,
        inlinePreviewable: true,
        mimeType: 'application/xhtml+xml',
    },
    xls: {
        kind: 'document',
        subKind: 'spreadsheet',
        maxPreviewableSize: 25165824,
        maxEditableSize: 11534336,
        maxThumbnailableSize: 5242880,
        onlyThumbnailableByHash: true,
        mimeType: 'application/vnd.ms-excel',
        msIcon: 'excel-online',
    },
    xlsb: {
        kind: 'document',
        subKind: 'spreadsheet',
        maxPreviewableSize: 25165824,
        maxEditableSize: 11534336,
        mimeType: 'application/vnd.ms-excel.sheet.binary.macroenabled.12',
        msIcon: 'excel-online',
    },
    xlsm: {
        kind: 'document',
        subKind: 'spreadsheet',
        maxPreviewableSize: 25165824,
        maxEditableSize: 11534336,
        maxThumbnailableSize: 5242880,
        mimeType: 'application/vnd.ms-excel.sheet.macroenabled.12',
        msIcon: 'excel-online',
    },
    xlsx: {
        kind: 'document',
        subKind: 'spreadsheet',
        maxPreviewableSize: 25165824,
        maxEditableSize: 11534336,
        maxThumbnailableSize: 5242880,
        onlyThumbnailableByHash: true,
        mimeType: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        msIcon: 'excel-online',
    },
    xltx: {
        kind: 'document',
        subKind: 'spreadsheet',
        maxPreviewableSize: 25165824,
        maxEditableSize: 25165824,
        mimeType: 'application/vnd.openxmlformats-officedocument.spreadsheetml.template',
    },
    xml: {
        kind: 'source',
        subKind: 'source',
        maxPreviewableSize: MAX_SOURCE_VIEW_SIZE,
        inlinePreviewable: true,
        mimeType: 'application/xml',
    },
    yaml: {
        kind: 'light-markup',
        subKind: 'source',
        maxPreviewableSize: MAX_SOURCE_VIEW_SIZE,
        inlinePreviewable: true,
        mimeType: 'text/yaml',
    },
    yml: {
        kind: 'light-markup',
        subKind: 'source',
        maxPreviewableSize: MAX_SOURCE_VIEW_SIZE,
        inlinePreviewable: true,
        mimeType: 'text/yaml',
    },
    zip: {
        kind: 'file',
        subKind: 'archive',
        maxPreviewableSize: 100 * 1024 * 1024 * 1024,
        mimeType: 'application/zip',
    },
    zipz: {
        kind: 'file',
        subKind: 'archive',
        mimeType: 'application/octet-stream',
    },
};

const extAttachConfig = {
    psd: {
        kind: 'image',
        subKind: 'photoshop',
        maxPreviewableSize: 31457280,
        maxThumbnailableSize: 31457280,
        showThumbnailInList: true,
        mimeType: 'image/vnd.adobe.photoshop',
    },
    bmp: {
        kind: 'image',
        subKind: 'picture',
        mimeType: 'image/bmp',
        maxPreviewableSize: 31457280,
        maxThumbnailableSize: 31457280,
        showThumbnailInList: true,
    },
    jpf: {
        kind: 'image',
        subKind: 'picture',
        maxPreviewableSize: 31457280,
        maxThumbnailableSize: 31457280,
        showThumbnailInList: true,
        mimeType: 'image/jpx',
    },
    tga: {
        kind: 'image',
        subKind: 'picture',
        maxPreviewableSize: 31457280,
        maxThumbnailableSize: 31457280,
        showThumbnailInList: true,
        mimeType: 'image/x-tga',
    },
    sr2: {
        kind: 'image',
        subKind: 'picture',
        mimeType: 'image/x-sony-sr2',
    },
    arw: {
        kind: 'image',
        subKind: 'picture',
        mimeType: 'image/x-sony-arw',
    },
    nrw: {
        kind: 'image',
        subKind: 'picture',
        mimeType: 'image/x-nikon-nrw',
    },
    cr2: {
        kind: 'image',
        subKind: 'picture',
        mimeType: 'image/x-nikon-nrw',
    },
};

const processConfig = function (extensions) {
    // Для файлов с расширениями "__proto__" и "constructor"
    // должно возвращаться значение unknownExtension.
    //
    // extInfo.get('__proto__') → Object.prototype
    // extInfo.get('constructor') → Object
    //
    // Простой способ wellKnownExtensions.__proto__ = null
    // приводит к появлению предупреждений в Firefox.
    const hash = Object.create(null);

    Object.keys(extensions).forEach(function (key) {
        hash[key] = extensions[key];
    });

    return hash;
};

/**
 * Описание известных типов файлов.
 */
const wellKnownExtensions = processConfig(extConfig);
const wellKnownAttachExtensions = processConfig(extAttachConfig);

const _getInfo = function (ext, isAttaches, isBizUser?) {
    const extension = String(ext).toLowerCase();
    const override = getFeature('override-ext-info');
    const extInfo = (isAttaches ? wellKnownAttachExtensions[extension] : null) || wellKnownExtensions[extension];
    const finalIsBizUser = typeof IS_B2B_BIZ_USER === 'undefined' ? isBizUser : IS_B2B_BIZ_USER;

    if (finalIsBizUser) {
        return processExtInfoB2B(ext, extInfo, override);
    } else if (override && override[extension]) {
        return override[extension];
    }

    return extInfo;
};

/**
 * Конфиг экстра расширений для редакторов и просмотрщиков документов
 */
export type DocumentEditorsAndViewersExtraConfig = Record<EditorID | 'default', number>;

export interface IExtInfo {
    kind: Kind;
    subKind: SubKind;
    mimeType: string;
    maxPreviewableSize?: number | DocumentEditorsAndViewersExtraConfig;
    maxThumbnailableSize?: number;
    showThumbnailInList?: boolean;
    maxEditableSize?: number | DocumentEditorsAndViewersExtraConfig;
    onlyThumbnailableByHash?: boolean;
    msIcon?: string;
    inlinePreviewable?: boolean;
    disablePreviewAsEditorOnPublic?: boolean;
    isStreamingSupported?: boolean;
    isNativeSupported?: boolean;
}

/**
 * Публичный интерфейс.
 */
export const extInfo = {
    /**
     * Возвращает описание файла по расширению.
     */
    get(ext: string, isAttaches = false, isBizUser?: boolean): IExtInfo {
        return _getInfo(ext, isAttaches, isBizUser) || unknownExtension;
    },

    /**
     * Добавляет/обновляет информацию о расширении файла.
     */
    set(ext: string, params: IExtInfo): void {
        const defaultExtData = this.get(ext);

        if (Object(params) === params) {
            wellKnownExtensions[String(ext).toLowerCase()] = {
                ...defaultExtData,
                ...params,
            };
        }
    },

    getAll(): { [key: string]: IExtInfo } {
        return wellKnownExtensions;
    },

    getExtsForGallery(kind?: GalleryCategoryType | null): string[] {
        const extensions =
            (kind &&
                {
                    [GalleryCategoryType.videos]: GALLERY_B2B_VIDEO_EXTENSIONS,
                    [GalleryCategoryType.images]: GALLERY_B2B_IMAGES_EXTENSIONS,
                    [GalleryCategoryType.all]: GALLERY_B2B_OTHER_EXTENSIONS,
                }[kind]) ||
            GALLERY_EXTENSIONS;
        return extensions.filter((ext) => wellKnownExtensions[ext]);
    },

    getExtsForAlbums(): string[] {
        return ALBUMS_EXTENSIONS.filter((ext) => wellKnownExtensions[ext]);
    },

    getExtsForAlbumsByKind(kindValue: string): string[] {
        return this.getExtsByKind(kindValue).filter((ext) => this.getExtsForAlbums().includes(ext));
    },

    getExtsByKind(kindValue: string, isAttaches = false): string[] {
        return Object.keys(wellKnownExtensions)
            .concat(Object.keys(wellKnownAttachExtensions))
            .filter((ext) => {
                const extData = _getInfo(ext, isAttaches);

                if (!extData) {
                    return false;
                }

                const { subKind: extSubKind, kind: extKind } = extData;

                const kind = typeof extKind == 'string' ? extKind : '';
                const subKind = typeof extSubKind == 'string' ? extSubKind : '';

                return kind.includes(kindValue) || subKind.includes(kindValue);
            });
    },

    getPhotoExts(): string[] {
        return this.getExtsByKind('image').filter(function (ext) {
            return !NON_PHOTO_IMAGES.includes(ext);
        });
    },

    /**
     * Возвращает тип файла по расширению.
     */
    getKind(ext: string, isAttaches = false) {
        return this.get(ext, isAttaches).kind;
    },

    /**
     * Возвращает MIME тип файла по расширению.
     */
    getContentType(ext: string, isAttaches = false): string | undefined {
        return this.get(ext, isAttaches).mimeType;
    },
};
