define(function (require, exports, module) {
    'use strict';

    let store = {
        get() {
            //
        },
        set() {
            //
        },
    };

    try {
        if (window.localStorage) {
            store = {
                get(key) {
                    let res;
                    try {
                        res = window.localStorage.getItem(key);
                        res = JSON.parse(res);
                    } catch (error) {}
                    return res;
                },
                set(key, value) {
                    value = typeof value == 'string' ? value : JSON.stringify(value);
                    try {
                        window.localStorage.setItem(key, value);
                    } catch (error) {}
                },
                remove(key) {
                    try {
                        window.localStorage.removeItem(key);
                    } catch (error) {}
                },
            };
        }
    } catch (error) {}

    module.exports = store;

    return store;
});
